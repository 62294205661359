import React from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import utils, {
  ChallengeEntityType,
  getNextButtonLabel,
  isStepAvailableForComplete,
  isStepOverdue,
  isStepResolved,
} from '../../views/utils';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import { useSettings } from '@wix/tpa-settings/react';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useLoading } from '../../../../../contexts/Loading/LoadingContext';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { ButtonSize as ButtonSIZE } from 'wix-ui-tpa/cssVars';
import { useStepIsUnavailable } from './utils/useStepIsUnavailable';
import { StepInTheFutureGuard } from '../StepInTheFutureGuard/StepInTheFutureGuard';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useVideoStepData } from '../../../../../hooks/useVideoStepData';
import { isUserCompleted } from "../../../../../contexts/User/helpers/userTypeHandlers";
import { useUser } from "../../../../../contexts/User/UserContext";
import TextWithDirection from '../../../../../components-shared/TextWithDirection';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { StepsNavigation } from './components/StepsNavigation';
import { Box } from '../../../../../components-shared/Box';
import { StepUndo } from './components/StepUndo';
import challengeSettings from '../../../settingsParams';
import { IControl } from './utils/types';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { Challenges } from '../../../../../editor/types/Experiments';

import { classes, st } from './StepControls.st.css';

export interface IStepControls extends IControl {
  completeBtnDataHook?: string;
  nextBtnDataHook?: string;
  currentStep: ParticipantStep;
  buttonState: string;
  onStepResolve(currentStep: ParticipantStep): Promise<void>;
}

export const StepControls: FCWithChildren<IStepControls> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { participant } = useUser();
  const { isResolveStepRequestInProgress } = useResolveStep();
  const { startInteraction } = useMonitoring();
  const stepId = props.currentStep?.id;
  const { experiments } = useExperiments();
  const isStepUndoEnabled = experiments.enabled(Challenges.enableUndoButton);
  const isCompletedChallenge = isUserCompleted(
    participant?.transitions?.[0]?.state,
  );

  const isStepUnavailable = useStepIsUnavailable();
  const { stepDescriptionLoaded } = useSections();
  const { hasRicosLoaded } = useLoading();
  const nextEntity = utils.getFollowingEntity(props.currentStep?.id, null, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  const isCompleteButtonShown =
    !isCompletedChallenge &&
    (isStepAvailableForComplete(props.currentStep) ||
      isStepOverdue(props.currentStep));

  const isUndoButtonShown = !isCompletedChallenge;

  const isGeneralNextButtonShown =
    isStepResolved(props.currentStep) &&
    nextEntity.type !== ChallengeEntityType.VOID;

  const { isVideo } = useVideoStepData(props.currentStep?.source);
  const { videoStepAccepted, isVideoLoadingError } = useSidebarLayoutBase();
  const [isVideoNotificationVisible, setIsVideoNotificationVisible] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setIsVideoNotificationVisible(false);
  }, [stepId, videoStepAccepted]);

  const shouldHideButton =
    isMobile && (!hasRicosLoaded || !stepDescriptionLoaded(stepId));

  if (isStepUnavailable || shouldHideButton) {
    return null;
  }

  const CompleteButton = isCompleteButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={false}
    >
      <SidebarLayoutButton
        className={classes.stepViewMainCTA}
        dataHook={props.completeBtnDataHook || null}
        disabled={isResolveStepRequestInProgress || isVideoLoadingError}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (isVideo && !videoStepAccepted) {
            setIsVideoNotificationVisible(true);
            return;
          }

          if (!isResolveStepRequestInProgress) {
            startInteraction(
              FedopsInteraction.CompleteStepWithoutFeedback,
              props.currentStep?.id,
            );

            await props.onStepResolve(props.currentStep);
          }
        }}
      >
        {settings.get(challengeSettings.completeButtonTxt) as string}
      </SidebarLayoutButton>
    </PreviewActionBlockerContainer>
  ) : null;

  const NextButton = isGeneralNextButtonShown ? (
    <SidebarLayoutButton
      className={classes.stepViewMainCTA}
      key={stepId}
      dataHook={props.nextBtnDataHook || null}
      disabled={isResolveStepRequestInProgress}
      fullWidth={isMobile}
      size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
      onClick={async () => {
        await props.onNextEntity(nextEntity);
      }}
      moveFocus={true}
    >
      {getNextButtonLabel({
        entity: nextEntity.type,
        t,
        nextButtonTxt: settings.get(challengeSettings.nextButtonTxt) as string,
      })}
    </SidebarLayoutButton>
  ) : null;

  const UndoButton = isUndoButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={false}
    >
      <StepUndo className={classes.undo}>{t('live-site.step.undo')}</StepUndo>
    </PreviewActionBlockerContainer>
  ) : null;

  // todo: remove if-else, .boxNextButton + order: -1 (after step undo merge)
  // todo: review all combinations of btns and texts for both resolved and unresolved step (mobile only) (article, video, quiz) (after step undo merge)
  // todo: fix the loader for movile view (mobile only) (article, video, quiz) (after step undo merge)

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        buttonState: props.buttonState,
        stepResolved: isStepResolved(props.currentStep),
      })}
      role="region"
      aria-live="polite"
    >
      {!isStepUndoEnabled ? (
        <StepInTheFutureGuard>
          {isStepResolved(props.currentStep) ? (
            <>
              {isMobile ? (
                <>
                  <p className={classes.textMessage}>
                    <TextWithDirection>
                      {t('challenge.page.step.completed')}
                    </TextWithDirection>
                  </p>
                  {NextButton}
                </>
              ) : (
                <>
                  <p className={classes.textMessage}>
                    <TextWithDirection>
                      {t('challenge.page.step.completed')}
                    </TextWithDirection>
                  </p>
                  <Box gap={6} className={classes.boxNextButton}>
                    {NextButton}
                  </Box>
                </>
              )}
            </>
          ) : null}
          {CompleteButton}
          {isVideoNotificationVisible ? (
            <p className={classes.textMessage}>
              <TextWithDirection>
                {t('challenge.page.step-video-required.warning')}
              </TextWithDirection>
            </p>
          ) : null}
        </StepInTheFutureGuard>
      ) : (
        <StepInTheFutureGuard>
          {isStepResolved(props.currentStep) ? (
            <p className={classes.textMessage}>
              <TextWithDirection>
                {t('challenge.page.step.completed')}
              </TextWithDirection>
            </p>
          ) : null}

          {!isStepResolved(props.currentStep) ? CompleteButton : UndoButton}

          {isVideoNotificationVisible ? (
            <p className={classes.textMessage}>
              <TextWithDirection>
                {t('challenge.page.step-video-required.warning')}
              </TextWithDirection>
            </p>
          ) : null}

          <StepsNavigation
            stepId={props.currentStep?.id}
            onChangeEntity={props.onNextEntity}
          />
        </StepInTheFutureGuard>
      )}
    </div>
  );
};
